import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import {WebService} from "../services/web.service";
import {UserIdStorageService} from "../services/user-id-storage.service";
import {tap} from "rxjs/operators";

@Injectable()
export class Logging implements HttpInterceptor {

    constructor(private userIdStorageService: UserIdStorageService, private http: HttpClient, private webService : WebService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(this.userIdStorageService.hasTemporaryUserId()){ // NECESITO VALIDAR ESTO PORQUE LO TENGO QUE HACER SOLO EN PROCESOS DE SUSCRIPCION
            const serviceUrl = req.urlWithParams; // URL del servicio que está siendo invocado
            // Omitir las solicitudes al endpoint /log para evitar el loop infinito
            // OMITIMOS los servicios GET

            if (serviceUrl.includes('/log') || req.method.includes("GET")) {
                // console.log("SALIMOS SIN LOG")
                return next.handle(req); // No hacer nada y continuar con la solicitud
            }

            // console.log("REQ: ", req)
            const requestBody = req.body; // Cuerpo del request (si tiene)
            const userId = this.userIdStorageService.getTemporaryUserId(); // Obtener el ID del usuario (de algún servicio de autenticación si es necesario)

            // Crear el payload para enviar al backend
            const logData = {
                userId: userId,
                serviceUrl: serviceUrl,
                requestBody: requestBody,
                method: req.method,
                type : "SUSCRIPTION-FORM",
                responseBody: null,
                responseStatus: null,
                responseTime: null
            };

            // Continuar con la solicitud original
            return next.handle(req).pipe(
                tap({
                    next: (event) => {
                        if (event instanceof HttpResponse) {
                            // Actualizar el logData con la información de la respuesta
                            console.log(JSON.stringify(event));
                            logData.responseBody = event.body;
                            logData.responseStatus = event.status;
                            logData.responseTime = new Date().toISOString();
                            console.log("ESTO ENVIO: ", JSON.stringify(logData, null, 2));
                            // Enviar el log completo al backend
                            this.http.post(this.webService.HOST + '/log', logData).subscribe({
                                next: () => console.log('Log completo guardado exitosamente.'),
                                error: (err) => console.error('Error guardando log:', err)
                            });
                        }
                    },
                    error: (error) => {
                        // Capturar también los errores en la respuesta
                        logData.responseBody = error;
                        logData.responseStatus = error.status;
                        logData.responseTime = new Date().toISOString();

                        this.http.post(this.webService.HOST + '/log', logData).subscribe({
                            next: () => console.log('Log de error guardado exitosamente.'),
                            error: (err) => console.error('Error guardando log:', err)
                        });
                    }
                })
            );

        }else {
            return next.handle(req);
        }

    }
}
