<div class="header">
  <div class="content-header">
    <div class="logo" >
      <img (click)="goHome()" [src]="logoUrl" alt="logo">
    </div>
    
    <!-- Menú normal para dispositivos de escritorio -->
    <div  class="menu" *ngIf="!isMobile">
     
    </div>

    <!-- Menú hamburguesa para dispositivos móviles -->
    <!-- Menú hamburguesa para dispositivos móviles -->
<!-- Botón hamburguesa para dispositivos móviles -->
<div class="hamburger-menu" *ngIf="isMobile" (click)="toggleMenu()">
  <button>
    <span></span>
    <span></span>
    <span></span>
  </button>
</div>

<!-- Menú para dispositivos móviles (inicialmente oculto) -->

  </div>
</div>
