<!-- <app-header></app-header> -->

<div class="header" *ngIf="suscription && suscription.plan">
  <ng-container *ngIf="urlimagedatail; else showName">
    <img [src]="urlimagedatail" alt="Logo del Servicio" class="service-logo">
  </ng-container>
  <ng-template #showName>
    <h4>{{ namepartner }}</h4>
  </ng-template>
</div>
<div class="checkout-container" [ngClass]="themeService.currentPalette">
    <div  *ngIf="isActive"   class="title-checkout"><h1>{{ 'CHECKOUT.TITLE' | translate }}</h1></div>
    <div  *ngIf="!isActive"   class="title-checkout"><h1>{{ 'CHECKOUT.MAINTENANCE' | translate }}</h1></div>
    <div class="checkout-detail">
    <div class="checkout-column left-column">
      <!-- Bloque de inicio de sesión y registro -->
      <!-- <div *ngIf="!auth.isLoggedIn()" class="login-register-block">
        <h2>¿Tiene cuenta?</h2>
        <div class="button-login">
        <button (click)="showLoginPopup()" class="btn-login">Iniciar Sesión</button>
        <button class="btn-register">Registrarse</button>
        </div>
      </div> -->
      
      <!-- Formulario de checkout -->
      <div *ngIf="isActive" class="checkout-form-block">
        <h2>{{ 'CHECKOUT.PAYMENT_INFORMATION' | translate }}</h2>
        <div class="form-column">
          <div class="form-group">
            <label for="street">{{ 'CHECKOUT.STREET' | translate }}: </label>
            <input [formControl]="form.controls['street']" type="text" id="street" name="street" placeholder="{{ 'CHECKOUT.STREET_PLACEHOLDER' | translate }}" required>
            <small
                *ngIf="form.controls['street'].hasError('required') && form.controls['street'].touched"
                class="form-error-msg">{{ 'CHECKOUT.STREET' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }} </small>
          </div>
          <div class="form-group">
            <label for="direccion">{{ 'CHECKOUT.CITY' | translate }}:</label>
            <input [formControl]="form.controls['city']" type="text" id="city" name="city" placeholder="{{ 'CHECKOUT.CITY_PLACEHOLDER' | translate }}" required>
            <small
                *ngIf="form.controls['city'].hasError('required') && form.controls['city'].touched"
                class="form-error-msg">  {{ 'CHECKOUT.CITY' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }} </small>
          </div>
          <div class="form-group">
            <label for="direccion">{{ 'CHECKOUT.POSTAL_CODE' | translate }}:</label>
            <input [formControl]="form.controls['postalCode']" type="text" id="postalCode" name="postalCode" placeholder="{{ 'CHECKOUT.POSTAL_CODE_PLACEHOLDER' | translate }}" required>
            <small
                *ngIf="form.controls['postalCode'].hasError('required') && form.controls['postalCode'].touched"
                class="form-error-msg"> {{ 'CHECKOUT.POSTAL_CODE' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }} </small>
          </div>

          <div class="form-group">
            <label for="direccion">{{ 'CHECKOUT.PHONE' | translate }}:</label>
            <input [formControl]="form.controls['phone']" type="text" id="phone" name="phone" placeholder="{{ 'CHECKOUT.PHONE_PLACEHOLDER' | translate }}" required>
            <small
                *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched"
                class="form-error-msg"> {{ 'CHECKOUT.PHONE' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }} </small>
          </div>
          
        </div>
        <form [formGroup]="form" >
            <!-- Columna izquierda del formulario -->
            

            <div class="checkout-dialog">
           
          </div>
         
            <div class="form-group">
              
                <label for="tarjeta">{{ 'CHECKOUT.CARD_NAME' | translate }}:</label>
                <input [formControl]="form.controls['nameHolderCreditCard']" type="text" id="tarjeta" name="cc-name" placeholder="{{ 'CHECKOUT.CARD_NAME_PLACEHOLDER' | translate }}" required>
                <small
                    *ngIf="form.controls['nameHolderCreditCard'].hasError('required') && form.controls['nameHolderCreditCard'].touched"
                    class="form-error-msg"> {{ 'CHECKOUT.CARD_NAME' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }} </small>
              </div>
              <div class="form-group">
                <label for="numero-tarjeta">{{ 'CHECKOUT.CARD_NUMBER' | translate }}:</label>
                <input
                  (input)="onCreditCardInput(); formatCreditCardNumber()"
                  (keypress)="onlyNumberKey($event)"
                  [formControl]="form.controls['numberCreditCard']"
                  type="text"
                  id="numero-tarjeta"
                  name="cc-number"
                  placeholder="{{ 'CHECKOUT.CARD_NUMBER_PLACEHOLDER' | translate }}"
                  required
                />
                <small
                  *ngIf="form.controls['numberCreditCard'].hasError('required') && form.controls['numberCreditCard'].touched"
                  class="form-error-msg"
                >
                  {{ 'CHECKOUT.CARD_NUMBER' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }}
                </small>
              </div>
              
              
              <div class="datos-mac">
              <div class="form-group">
                <label for="mes-expiracion">{{ 'CHECKOUT.EXP_MONTH' | translate }}:</label>
                <select [formControl]="form.controls['expMonthCreditCard']"  id="mes-expiracion" name="mes-expiracion" required>
                  <option value="" disabled selected>{{ 'CHECKOUT.EXP_MONTH_PLACEHOLDER' | translate }}</option>
                  <option value="01" id="mes-exp-0">{{ 'CHECKOUT.JANUARY' | translate }}</option>
                  <option value="02" id="mes-exp-1">{{ 'CHECKOUT.FEBRUARY' | translate }}</option>
                  <option value="03" id="mes-exp-2">{{ 'CHECKOUT.MARCH' | translate }}</option>
                  <option value="04" id="mes-exp-3">{{ 'CHECKOUT.APRIL' | translate }}</option>
                  <option value="05" id="mes-exp-4">{{ 'CHECKOUT.MAY' | translate }}</option>
                  <option value="06" id="mes-exp-5">{{ 'CHECKOUT.JUNE' | translate }}</option>
                  <option value="07" id="mes-exp-6">{{ 'CHECKOUT.JULY' | translate }}</option>
                  <option value="08" id="mes-exp-7">{{ 'CHECKOUT.AUGUST' | translate }}</option>
                  <option value="09" id="mes-exp-8">{{ 'CHECKOUT.SEPTEMBER' | translate }}</option>
                  <option value="10" id="mes-exp-9">{{ 'CHECKOUT.OCTOBER' | translate }}</option>
                  <option value="11" id="mes-exp-10">{{ 'CHECKOUT.NOVEMBER' | translate }}</option>
                  <option value="12" id="mes-exp-11">{{ 'CHECKOUT.DECEMBER' | translate }}</option>
                </select>
                <small
                *ngIf="form.controls['expMonthCreditCard'].hasError('required') && form.controls['expMonthCreditCard'].touched"
                class="form-error-msg"> {{ 'CHECKOUT.EXP_MONTH' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }}</small>

              </div>
              <div class="form-group">
                <label for="ano-expiracion">{{ 'CHECKOUT.FORM.EXPIRATION_YEAR' | translate }}:</label>
                <select [formControl]="form.controls['expYearCreditCard']" id="ano-expiracion" name="ano-expiracion" required>
                  <option value="" disabled selected>{{ 'CHECKOUT.FORM.SELECT_YEAR' | translate }}</option>
                  <option *ngFor="let year of expirationYears" [value]="year.value">
                    {{ year.label }}
                  </option>
                </select>
                <small
                *ngIf="form.controls['expYearCreditCard'].hasError('required')
                       && form.controls['expYearCreditCard'].touched"
                class="form-error-msg">
                {{ 'CHECKOUT.FORM.REQUIRED' | translate }}
              </small>
              </div>
              <div class="form-group">
                <label for="cvv">{{ 'CHECKOUT.CVV' | translate }}:</label>
                <input maxlength="4"  (keypress)="onlyNumberKey($event)" (input)="onCVVInputChange()" [formControl]="form.controls['ccv']" type="password" id="cvv" name="cvv" placeholder="{{ 'CHECKOUT.CVV_PLACEHOLDER' | translate }}" required>
                <small
                *ngIf="form.controls['ccv'].hasError('required') && form.controls['ccv'].touched"
                class="form-error-msg"> {{ 'CHECKOUT.CVV' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }}</small>
              </div> </div>
            <button (click)="checkout()" class="btn-submit">{{ 'CHECKOUT.CHECKOUT_BUTTON' | translate }}</button>
          
          </form>
      </div>
    </div>
  
  
<div *ngIf="isActive" class="checkout-summary">
    <h2>{{ 'CHECKOUT.ORDER_SUMMARY' | translate }}</h2>
    <div class="product-item">
      <img [src]="planImage"  alt="Producto 1">
      <div class="product-info">
        <h3>{{ plan?.name_plan }}</h3>
        <p>{{ 'CHECKOUT.DESCRIPTION' | translate }}: </p><p>{{ description }} </p>
      </div>
    </div>
    <hr>
    <!-- Total -->
    <div class="order-total">
      <p>{{ 'CHECKOUT.TOTAL' | translate }}: ${{ feeCost_plan }}</p>
    </div>
  </div>
  
</div>
  </div>
