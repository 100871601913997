import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { ThemeService } from '../../theme.service';

@Component({
  selector: 'app-congratulation',
  templateUrl: './congratulation.component.html',
  styleUrls: ['./congratulation.component.scss']
})
export class CongratulationComponent implements OnInit {
  countdown: number = 5;

  constructor(private router: Router,public themeService: ThemeService) {}

  ngOnInit() {
    const counter = interval(1000).pipe(take(this.countdown));
    counter.subscribe(
      (value) => this.countdown = this.countdown - 1,
      (err) => console.error(err),
      () => this.navigateToHome()
    );
  }

  navigateToHome() {
    // this.router.navigateByUrl('/dashboard/suscripciones');
  }
  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }
}
