import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ThemeService} from "../../theme.service";
import {interval} from "rxjs";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-update-payment-success',
  templateUrl: './update-payment-success.component.html',
  styleUrls: ['./update-payment-success.component.scss']
})
export class UpdatePaymentSuccessComponent implements OnInit{
  countdown: number = 5;

  constructor(private router: Router,public themeService: ThemeService) {}

  ngOnInit() {
    const counter = interval(1000).pipe(take(this.countdown));
    counter.subscribe(
        (value) => this.countdown = this.countdown - 1,
        (err) => console.error(err),
        () => this.navigateToHome()
    );
  }

  navigateToHome() {
    // this.router.navigateByUrl('/dashboard/suscripciones');
  }
  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }
}
