<!-- <app-header></app-header> -->

<div class="header" *ngIf="suscription && suscription.plan">
  <ng-container *ngIf="urlimagedatail; else showName">
    <img [src]="urlimagedatail" alt="Logo del Servicio" class="service-logo">
  </ng-container>
  <ng-template #showName>
    <h4>{{ namepartner }}</h4>
  </ng-template>
</div>

<div class="checkout-container" [ngClass]="themeService.currentPalette">
  <div *ngIf="isActive" class="title-checkout"><h1>{{ 'CHECKOUT.TITLE' | translate }}</h1></div>
  <div *ngIf="!isActive" class="title-checkout"><h1>{{ 'CHECKOUT.MAINTENANCE' | translate }}</h1></div>
  
  <div class="checkout-detail">
    <div class="checkout-column left-column">
      
      <div *ngIf="isActive" class="checkout-form-block">
        <h2>{{ 'CHECKOUT.PAYMENT_INFORMATION' | translate }}</h2>
        <div *ngIf="missingFields.length > 0" class="form-errors-list">
          <ul>
            <li *ngFor="let field of missingFields">
              <span class="form-error-msg">⚠ {{ field }} {{ 'CHECKOUT.REQUIRED' | translate }}</span>
            </li>
          </ul>
        </div>
        <div class="form-column">
          <div class="form-group">
            <label for="street">{{ 'CHECKOUT.STREET' | translate }}: </label>
            <input [formControl]="street" type="text" id="street" placeholder="{{ 'CHECKOUT.STREET_PLACEHOLDER' | translate }}"  [class.invalid-input]="postalCode.touched && postalCode.invalid" required />
            <small *ngIf="street.touched && street.hasError('required')" class="form-error-msg">
              {{ 'CHECKOUT.STREET' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }}
            </small>
          </div>

          <div class="form-group">
            <label for="city">{{ 'CHECKOUT.CITY' | translate }}:</label>
            <input [formControl]="city" type="text" id="city" placeholder="{{ 'CHECKOUT.CITY_PLACEHOLDER' | translate }}"  [class.invalid-input]="postalCode.touched && postalCode.invalid" required />
            <small *ngIf="city.touched && city.hasError('required')" class="form-error-msg">
              {{ 'CHECKOUT.CITY' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }}
            </small>
          </div>

          <!-- <div class="form-group">
            <label for="postalCode">{{ 'CHECKOUT.POSTAL_CODE' | translate }}:</label>
            <input [formControl]="postalCode" type="text" id="postalCode" placeholder="{{ 'CHECKOUT.POSTAL_CODE_PLACEHOLDER' | translate }}"  [class.invalid-input]="postalCode.touched && postalCode.invalid" required />
            <small *ngIf="postalCode.touched && postalCode.hasError('required')" class="form-error-msg">
              {{ 'CHECKOUT.POSTAL_CODE' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }}
            </small>
          </div> -->

          <div class="form-group">
            <label for="phone">{{ 'CHECKOUT.PHONE' | translate }}:</label>
            <input [formControl]="phone" type="text" id="phone" placeholder="{{ 'CHECKOUT.PHONE_PLACEHOLDER' | translate }}"  [class.invalid-input]="postalCode.touched && postalCode.invalid" required />
            <small *ngIf="phone.touched && phone.hasError('required')" class="form-error-msg">
              {{ 'CHECKOUT.PHONE' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }}
            </small>
          </div>
        </div>

        <form [formGroup]="form">
          <div class="checkout-dialog"></div>

          <div class="form-group">
            <label for="tarjeta">{{ 'CHECKOUT.CARD_NAME' | translate }}:</label>
            <input [formControl]="nameHolderCreditCard" type="text" id="tarjeta" placeholder="{{ 'CHECKOUT.CARD_NAME_PLACEHOLDER' | translate }}"  [class.invalid-input]="postalCode.touched && postalCode.invalid" required />
            <small *ngIf="nameHolderCreditCard.touched && nameHolderCreditCard.hasError('required')" class="form-error-msg">
              {{ 'CHECKOUT.CARD_NAME' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }}
            </small>
          </div>

          <div class="form-group">
            <label for="numero-tarjeta">{{ 'CHECKOUT.CARD_NUMBER' | translate }}:</label>
            <input
              (input)="onCreditCardInput(); formatCreditCardNumber()"
              (keypress)="onlyNumberKey($event)"
              [formControl]="numberCreditCard"
              type="text"
              id="numero-tarjeta"
              placeholder="{{ 'CHECKOUT.CARD_NUMBER_PLACEHOLDER' | translate }}"
              required
            />
            <small *ngIf="numberCreditCard.touched && numberCreditCard.hasError('required')" class="form-error-msg">
              {{ 'CHECKOUT.CARD_NUMBER' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }}
            </small>
          </div>

          <div class="datos-mac">
            <div class="form-group">
              <label for="mes-expiracion">{{ 'CHECKOUT.EXP_MONTH' | translate }}:</label>
              <select [formControl]="expMonthCreditCard" id="mes-expiracion"  [class.invalid-input]="postalCode.touched && postalCode.invalid" required>
                <option value="" disabled selected>{{ 'CHECKOUT.EXP_MONTH_PLACEHOLDER' | translate }}</option>
                <option value="01">{{ 'CHECKOUT.JANUARY' | translate }}</option>
                <option value="02">{{ 'CHECKOUT.FEBRUARY' | translate }}</option>
                <option value="03">{{ 'CHECKOUT.MARCH' | translate }}</option>
                <option value="04">{{ 'CHECKOUT.APRIL' | translate }}</option>
                <option value="05">{{ 'CHECKOUT.MAY' | translate }}</option>
                <option value="06">{{ 'CHECKOUT.JUNE' | translate }}</option>
                <option value="07">{{ 'CHECKOUT.JULY' | translate }}</option>
                <option value="08">{{ 'CHECKOUT.AUGUST' | translate }}</option>
                <option value="09">{{ 'CHECKOUT.SEPTEMBER' | translate }}</option>
                <option value="10">{{ 'CHECKOUT.OCTOBER' | translate }}</option>
                <option value="11">{{ 'CHECKOUT.NOVEMBER' | translate }}</option>
                <option value="12">{{ 'CHECKOUT.DECEMBER' | translate }}</option>
              </select>
              <small *ngIf="expMonthCreditCard.touched && expMonthCreditCard.hasError('required')" class="form-error-msg">
                {{ 'CHECKOUT.EXP_MONTH' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }}
              </small>
            </div>

            <div class="form-group">
              <label for="ano-expiracion">{{ 'CHECKOUT.FORM.EXPIRATION_YEAR' | translate }}:</label>
              <select [formControl]="expYearCreditCard" id="ano-expiracion"  [class.invalid-input]="postalCode.touched && postalCode.invalid" required>
                <option value="" disabled selected>{{ 'CHECKOUT.FORM.SELECT_YEAR' | translate }}</option>
                <option *ngFor="let year of expirationYears" [value]="year.value">{{ year.label }}</option>
              </select>
              <small *ngIf="expYearCreditCard.touched && expYearCreditCard.hasError('required')" class="form-error-msg">
                {{ 'CHECKOUT.FORM.REQUIRED' | translate }}
              </small>
            </div>

            <div class="form-group">
              <label for="cvv">{{ 'CHECKOUT.CVV' | translate }}:</label>
              <input
                maxlength="4"
                (keypress)="onlyNumberKey($event)"
                (input)="onCVVInputChange()"
                [formControl]="ccv"
                type="password"
                id="cvv"
                placeholder="{{ 'CHECKOUT.CVV_PLACEHOLDER' | translate }}"
                [class.invalid-input]="postalCode.touched && postalCode.invalid"
                required
              />
              <small *ngIf="ccv.touched && ccv.hasError('required')" class="form-error-msg">
                {{ 'CHECKOUT.CVV' | translate }} {{ 'CHECKOUT.REQUIRED' | translate }}
              </small>
            </div>
          </div>

          <button (click)="checkout()" class="btn-submit">{{ 'CHECKOUT.CHECKOUT_BUTTON' | translate }}</button>
        </form>
      </div>
    </div>

    <div *ngIf="isActive" class="checkout-summary">
      <h2>{{ 'CHECKOUT.ORDER_SUMMARY' | translate }}</h2>
      <div class="product-item">
        <img [src]="planImage" alt="Producto" />
        <div class="product-info">
          <h3>{{ plan?.name_plan }}</h3>
          <p>{{ 'CHECKOUT.DESCRIPTION' | translate }}: {{ description }}</p>
        </div>
      </div>
      <hr />
      <div class="order-total">
        <p>{{ 'CHECKOUT.TOTAL' | translate }}: ${{ feeCost_plan }}</p>
      </div>
    </div>
  </div>
</div>
