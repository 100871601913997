import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserIdStorageService {

  private readonly USER_ID_KEY = 'temp_user_id';

  constructor() {}

  setTemporaryUserId(userId: string): void {
    if (userId) {
      localStorage.setItem(this.USER_ID_KEY, userId);
    }
  }

  getTemporaryUserId(): string | null {
    return localStorage.getItem(this.USER_ID_KEY);
  }

  clearTemporaryUserId(): void {
    localStorage.removeItem(this.USER_ID_KEY);
  }

  hasTemporaryUserId(): boolean {
    return !!this.getTemporaryUserId();
  }

}
